import Vue from 'vue';

Vue.filter('currencySign', (value, facilityData) => {
  // set currency as usd if not set or loaded.
  const currency = facilityData && facilityData.pricing && facilityData.pricing.currency ? facilityData.pricing.currency : 'usd';
  return Number(value).toLocaleString('en', {
    style: 'currency',
    currency,
  });
});
