/* eslint-disable max-len */
<template>
  <svg
    width="140px"
    height="28px"
    fill="#efefef"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 212 35"
    xml:space="preserve"
  >
    <path
      d="M129.6,7.7c-2.3,0-4.5,1-5.9,2.5V8.5c0-0.2-0.1-0.3-0.3-0.3h-4.6c-0.2,0-0.3,0.1-0.3,0.3v25.6
	c0,0.2,0.2,0.3,0.3,0.3h4.5c0.2,0,0.3-0.2,0.3-0.3v-8.9c1.4,1.5,3.4,2.3,5.5,2.3c5.2,0,9-4.2,9-9.9C138.2,11.7,134.6,7.7,129.6,7.7
	M132.7,19.1c-0.4,1.7-1.8,3-3.4,3.3c-1.8,0.4-3.4-0.1-4.6-1.3c-1.2-1.3-1.6-3.2-1.1-5.1c0.4-1.7,1.8-3,3.4-3.3
	c0.4-0.1,0.8-0.1,1.1-0.1c1.3,0,2.5,0.5,3.4,1.4C132.8,15.3,133.2,17.2,132.7,19.1"
    />
    <path
      d="M15.3,16c3.8-1.5,5.7-4.1,5.7-7.7c0-4.7-3.6-7.6-9.3-7.6H0.9C0.7,0.7,0.6,0.8,0.6,1v25.6c0,0.2,0.1,0.3,0.3,0.3
	h4.8c0.2,0,0.3-0.1,0.3-0.3V16.5h3.6l5.8,10.3c0.1,0.1,0.2,0.2,0.3,0.2H21c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0-0.3L15.3,16z
	M15.6,8.6c0,2.5-2.1,3.1-3.9,3.1H5.9V5.6h5.7C13.5,5.6,15.6,6.1,15.6,8.6z"
    />
    <path
      d="M78.3,0.7h-4.6c-0.2,0-0.3,0.1-0.3,0.3v8.8c-1.2-1.4-3.1-2.1-5.3-2.1c-4.4,0-9.1,3.5-9.1,9.9
	c0,5.8,3.8,9.9,9.1,9.9c2.5,0,4.3-1.1,5.3-2.4v1.6c0,0.2,0.1,0.3,0.3,0.3h4.6c0.2,0,0.3-0.1,0.3-0.3V1C78.6,0.8,78.5,0.7,78.3,0.7
	M73.8,17.6c0,2.9-2,5-4.8,5c-2.8,0-4.8-2.1-4.8-5s2-5,4.8-5C71.8,12.6,73.8,14.7,73.8,17.6"
    />
    <path
      d="M108.5,11.1C108.5,11.1,108.5,11.1,108.5,11.1c-2.3-0.5-3.8-1-4.7-1.7c-0.1-0.1-0.2-0.1-0.3,0
	c-0.1,0-0.2,0.1-0.2,0.2l-1.7,4.2c-0.1,0.1,0,0.3,0.1,0.4c1.1,0.6,2.8,1,4.2,1.4c4.2,1,5.7,1.8,5.7,3.9c0,1.9-1.7,3.1-4.4,3.1
	c-2.4,0-5.3-1.3-7.3-3.4c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0.1-0.2,0.2l-1.8,4.4c-0.1,0.1,0,0.3,0.1,0.4c1.5,1.6,4.9,3.3,9.6,3.3
	c6.4,0,9.7-4.1,9.7-8.2C117.1,14.9,114.3,12.3,108.5,11.1"
    />
    <path
      d="M99.6,8.1C99.5,8,99.5,8,99.4,8h-5.2c-0.1,0-0.2,0.1-0.3,0.2l-4.2,11.2L85.4,8.2c0-0.1-0.2-0.2-0.3-0.2H80
	c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0.1-0.1,0.2,0,0.3L87,26.8l-0.5,1.3c-0.4,1.1-0.9,1.5-1.7,1.5c-0.5,0-1-0.1-1.5-0.4
	c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0.1-0.2,0.2l-0.7,4.3c0,0.1,0,0.3,0.2,0.4c0.7,0.3,1.7,0.4,2.6,0.4c3,0,4.9-1.4,6.2-4.6l8.5-21.5
	C99.7,8.3,99.7,8.2,99.6,8.1"
    />
    <path
      d="M30.4,7.7c-5.6,0-9.7,4.2-9.7,9.9c0,5.6,4.1,9.9,9.5,9.9c4.2,0,7.2-1.7,8.9-5c0-0.1,0-0.2,0-0.3
	c0-0.1-0.1-0.1-0.2-0.2l-4-1.3c-0.1,0-0.3,0-0.3,0.1c-1.1,1.4-2.5,2.1-4.3,2.1c-2.4,0-4.2-1.5-4.6-3.9h13.5c0.2,0,0.3-0.1,0.3-0.3
	V17C39.5,11.5,35.8,7.7,30.4,7.7 M30.3,11.8c2.2,0,3.8,1.1,4.3,3.1h-8.7C26.6,13,28.3,11.8,30.3,11.8"
    />
    <path
      d="M186,7.7c-5.6,0-9.7,4.2-9.7,9.9c0,5.6,4.1,9.9,9.5,9.9c4.2,0,7.2-1.7,8.9-5c0-0.1,0-0.2,0-0.3
	c0-0.1-0.1-0.1-0.2-0.2l-4-1.3c-0.1,0-0.3,0-0.3,0.1c-1.1,1.4-2.5,2.1-4.3,2.1c-2.4,0-4.2-1.5-4.6-3.9h13.5c0.2,0,0.3-0.1,0.3-0.3
	V17C195.1,11.5,191.4,7.7,186,7.7z M181.5,14.8c0.8-1.9,2.4-3.1,4.4-3.1c2.2,0,3.8,1.1,4.3,3.1H181.5z"
    />
    <path
      d="M175.7,22.2l-4.3-1.4c-0.1,0-0.3,0-0.3,0.1c-0.9,1.2-2,1.7-3.6,1.7c-2.7,0-4.5-2-4.5-5c0-2.9,1.8-5,4.5-5
	c1.4,0,2.4,0.4,3.2,1.4c0.1,0.1,0.2,0.1,0.3,0.1l4.5-1.5c0.1,0,0.1-0.1,0.2-0.2c0-0.1,0-0.2,0-0.3c-1.4-3-4.2-4.5-8.2-4.5
	c-5.6,0-9.7,4.3-9.7,9.9c0,5.7,4.1,9.9,9.7,9.9c3.7,0,6.4-1.6,8.4-4.8c0-0.1,0.1-0.2,0-0.3C175.9,22.3,175.8,22.2,175.7,22.2"
    />
    <path
      d="M57.4,22.1v-7.6c0-4.5-2.8-6.9-8.2-6.9c-2.3,0-4.3,0.6-5.8,1.8c-1.3,1.1-2.1,2.6-2.1,4.1c0,0.2,0,0.4,0,0.5
	c0,0.1,0,0.2,0.1,0.2c0.1,0.1,0.1,0.1,0.2,0.1l4.3-0.5c0.2,0,0.3-0.2,0.3-0.3c0-0.5,0.1-0.9,0.4-1.2c0.5-0.5,1.4-0.8,2.5-0.8
	c2,0,3,0.8,3,2.4v0.4l-5.1,0.8c-4.2,0.6-6.7,2.9-6.7,6.3c0,3.6,2.5,6,6.3,6c2.4,0,4.5-1,5.8-2.8c0.2,1.2,0.6,1.9,0.9,2.2
	c0.1,0.1,0.1,0.1,0.2,0.1h4.6c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0-0.2,0-0.3C57.7,25.2,57.4,24.1,57.4,22.1z M52.2,18.2
	c0,3-1.7,4.9-4.4,4.9c-0.9,0-2.4-0.2-2.4-1.9c0-1.3,0.9-2.1,2.8-2.4L52.2,18.2z"
    />
    <path
      d="M205.2,15.2C205.2,15.2,205.2,15.2,205.2,15.2c-2.7-0.6-4-1.3-4-2.3c0-1.2,1.8-1.3,2.4-1.3
	c1.3,0,2.5,0.5,3.3,1.6c0.1,0.1,0.2,0.1,0.3,0.1L211,12c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0-0.2,0-0.3c-1.7-2.9-3.7-3.9-7.6-3.9
	c-1.9,0-3.6,0.5-4.8,1.4c-1.4,1-2.2,2.5-2.2,4.2c0,3.1,2.2,5.1,6.5,6c2.6,0.5,3.6,1.1,3.6,2c0,1.1-1.3,1.7-2.5,1.7
	c-1.7,0-3.1-0.8-4.2-2.3c-0.1-0.1-0.2-0.2-0.3-0.1l-4.2,1.4c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0,0.2,0,0.3c2,3.4,4.8,5.1,8.8,5.1
	c4.4,0,7.4-2.6,7.4-6.4C211.4,17.9,209.5,16.1,205.2,15.2"
    />
    <path
      d="M105.6,5.2C105.6,5.2,105.6,5.2,105.6,5.2c2.8,0,4.8,0.7,6.2,2.2c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0
	l4-2.3c0.1,0,0.1-0.1,0.1-0.2c0-0.1,0-0.2-0.1-0.2c-2-2.4-5.5-4-9-4.1c-0.1,0-0.2,0.1-0.3,0.2l-1.7,4.1c0,0.1,0,0.2,0,0.3
	C105.4,5.1,105.5,5.2,105.6,5.2"
    />
    <path
      d="M156.2,22.1v-7.6c0-4.5-2.8-6.9-8.2-6.9c-2.3,0-4.3,0.6-5.8,1.8c-1.3,1.1-2.1,2.6-2.1,4.1c0,0.2,0,0.5,0,0.5
	c0,0.1,0,0.2,0.1,0.2c0.1,0.1,0.1,0.1,0.2,0.1l4.3-0.5c0.2,0,0.3-0.2,0.3-0.3c0-0.5,0.1-0.9,0.4-1.2c0.5-0.5,1.4-0.8,2.5-0.8
	c2,0,3,0.8,3,2.4v0.4l-5.1,0.8c-4.2,0.6-6.7,2.9-6.7,6.3c0,3.6,2.5,6,6.3,6c2.4,0,4.5-1,5.8-2.8c0.2,1.2,0.6,1.9,0.9,2.2
	c0.1,0.1,0.1,0.1,0.2,0.1h4.6c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0-0.2,0-0.3C156.4,25.2,156.2,24.1,156.2,22.1z M151,18.2
	c0,3-1.7,4.9-4.4,4.9c-0.9,0-2.4-0.2-2.4-1.9c0-1.3,0.9-2.1,2.8-2.4L151,18.2z"
    />
  </svg>
</template>

<script>
export default {
  name: "RSLogo",
};
</script>
