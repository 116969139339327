<template>
  <v-app>
    <v-app-bar
      app
      color="#009aca"
      dark
      absolute
    >
      <v-app-bar-title>
        <div class="d-flex align-center">
          <v-img
            alt="RS Logo"
            class="shrink mr-2"
            contain
            src="https://email-attachments-public.s3.us-east-2.amazonaws.com/rs.png"
            transition="scale-transition"
            width="40"
          />
          <RSLogo class="toolbar-title" />
        </div>
      </v-app-bar-title>


      <v-spacer />
  
      <div class="d-flex align-center ">
        <v-btn
          icon
          block
          @click="() => $keycloak.logout()"
        >
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </div>
    </v-app-bar>
    <v-main>
      <v-alert
        v-if="showError"
        dismissible
        border="top"
        type="error"
        dark
      >
        {{ error }}
      </v-alert>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import RSLogo from '@/components/RSLogo.vue';

export default {
  name: 'App',
  components: { RSLogo },
  data: () => ({
    drawer: false,
    group: null,
    error: '',
    showError: false,
  }),
};
</script>

<style scoped>

.toolbar-title {
  margin-top: 7px;
}
</style>
