import Vue from 'vue'
import Keycloak from 'keycloak-js';
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import { SSO_API_BASE } from './env';
import './utils/filters';

Vue.config.productionTip = false

const initOptions = {
  url: SSO_API_BASE, realm: 'customers', clientId: 'crm', onLoad: 'login-required',
};

const keycloak = Keycloak(initOptions);

keycloak.init({ onLoad: initOptions.onLoad }).then( auth => {
  if (auth) {
    Sentry.init({
      Vue,
      dsn: "https://83d2d558f3ac462c93af4c6b48cf13d1@o1028018.ingest.sentry.io/5995127",
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ["localhost", "crm.api.readyspaces.com", /^\//],
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      tracesSampleRate: 1.0,
    });
    
    new Vue({
      vuetify,
      router,
      render: h => h(App)
    }).$mount('#app')
    Vue.prototype.$sentry = Sentry;
  } else {
    keycloak.login({ redirectUri: this.$route.toPath() });
  }

  setInterval(() => {
    keycloak.updateToken(70).then((refreshed) => {
      if (refreshed) {
        Vue.prototype.$keycloak = keycloak;
      }
      // else {
      //   Vue.$log.warn(`Token not refreshed, valid for ${
      //     // eslint-disable-next-line no-mixed-operators
      //     Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000)} seconds`);
      // }
    }).catch(() => {
      // Vue.$log.error('Failed to refresh token');
    });
  }, 60000);
});

Vue.prototype.$keycloak = keycloak;
